<template>
  <div class="callsTable mt-4">
    <h1 class="main-color">
      {{ $t("Students QR Codes") }}
    </h1>
    <v-card-text>
      <v-form ref="form" v-model="valid" class="mt-5">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <label>{{ $t("Student Code") }}</label>
            <v-text-field
              v-model.trim="code"
              :rules="[validationRules.required]"
              solo
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              class="modal-btn-save saveBtn"
              @click="showQR"
              :loading="loading"
              >{{ $t("Show QR Code") }}</v-btn
            >
          </v-col>

          <v-col cols="12" sm="12" md="12" class="text-center">
            <img :src="qr_path" v-if="qr_path != ''" alt="" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions> </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      timeout="4000"
      color="red"
      :multi-line="true"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ errormsg }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "studentsQrCode",
  data() {
    return {
      valid: true,
      loading: false,
      snackbar: false,
      code: "",
      qr_path: "",
      errormsg: "",
    };
  },
  mixins: [validationMixin],
  methods: {
    showQR() {
      if (this.valid) {
        this.qr_path = "";
        this.$http
          .get(this.getApiUrl + "/nedaa/qr/" + this.code, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == false) {
              this.qr_path = response.data.data.qr_path;
              //
            } else {
              this.snackbar = true;
              this.errormsg = response.data.status.message;
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
  },
  mounted() {
    // this.getAllClassesQR();
  },
};
</script>

<style></style>
